<template>
    <div>
        <div class="field grid">
            <label class="col-fixed text-700 mt-1 w-4">
                Freie Kapazität
            </label>
            <div class="col">
                <input-number
                    class="w-6"
                    mode="decimal"
                    showButtons
                    :min="0"
                    :max="100"
                    v-model="value['openCapacity']"
                />
                <errors :errors="errors['openCapacity']"/>
            </div>
        </div>

        <div class="field grid">
            <label class="col-fixed text-700 mt-1 w-4">
                Model (aus Kundensicht)
            </label>
            <div class="col">
                <SelectButton
                    v-model="value['placeTypes']"
                    :options="getPlaceTypeOptions()"
                    :multiple="true"
                    option-label="label"
                    option-value="id"
                />
                <errors :errors="errors['placeTypes']"/>
            </div>
        </div>

        <template v-if="value.hasOwnProperty('placeTypes')">
            <div class="field grid" v-if="value['placeTypes'].indexOf('extern') > -1">
                <label class="col-fixed text-700 mt-1 w-4">
                    Standort (Extern)
                </label>
                <div class="col">
                    <input-text v-model="value['zip']" class="w-6"/>
                    <errors :errors="errors['zip']"/>
                </div>
            </div>

            <div class="field grid" v-if="value['placeTypes'].indexOf('intern') > -1">
                <label class="col-fixed text-700 mt-1 w-4">
                    Maximale Reisebereitschaft (vom Standort des Dienstleisters)
                </label>
                <div class="col">
                    <div class="p-inputgroup w-6">
                        <input-number v-model="value['maximalDistance']" class="w-10"/>
                        <span class="p-inputgroup-addon w-2 text-center">km</span>
                    </div>
                    <errors :errors="errors['maximalDistance']"/>
                </div>
            </div>
        </template>

        <div class="field grid">
            <label class="col-fixed text-700 mt-1 w-4">
                Barrierefrei
            </label>
            <div class="col">
                <SelectButton
                    v-model="value['barrierFree']"
                    :options="[{id: true, label: 'Ja'}, {id: false, label: 'Nein'}, {id: null, label: 'Keine Auskunft'}]"
                    :multiple="false"
                    option-label="label"
                    option-value="id"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Value from "@/mixin/Value";
    import Calendar from 'primevue/calendar';
    import SelectButton from "primevue/selectbutton/SelectButton";
    import Checkbox from 'primevue/checkbox';
    import InputNumber from "primevue/inputnumber/InputNumber";
    import InputText from "primevue/inputtext/InputText";
    import Errors from "@/components/form/Errors";

    export default {
        components: {Errors, SelectButton, Calendar, Checkbox, InputNumber, InputText},
        mixins: [Value],
        methods: {
            getPlaceTypeOptions() {
                return Object
                    .entries(this.$store.getters.settings.model.availability['placeType'])
                    .map(([key, value]) => ({id: key, label: value}));
            }
        }
    }
</script>
