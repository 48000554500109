<template>
    <div v-if="categories.length > 0">
        <div class="mb-6">
            <div class="text-lg uppercase mb-3">Ziel & Wirkung</div>
            <div class="field grid mb-4">
                <label class="col-fixed text-700 mt-1 w-4">
                    Wirkung auf den BHI
                </label>
                <div class="col">
                    <Dropdown
                        class="w-full"
                        v-model="value['impactBhiCategory']"
                        :options="impactBhiOptions"
                        option-label="name"
                        option-value="@id"
                        placeholder="Auswählen"
                    />
                    <errors :errors="$v.value.impactBhiCategory.$invalid ? ['Bitte Wert auswählen']: []"/>
                </div>
            </div>
            <div class="field grid mb-4">
                <label class="col-fixed text-700 mt-1 w-4">
                    Kategorien der Dienstleistungen, Art der Intervention
                </label>
                <div class="col">
                    <Dropdown
                        class="w-full"
                        v-model="value['typeCategory']"
                        :options="typeOptions"
                        optionLabel="name"
                        option-value="@id"
                        placeholder="Auswählen"
                    />
                    <errors :errors="$v.value.typeCategory.$invalid ? ['Bitte Wert auswählen']: []"/>
                </div>
            </div>
        </div>

        <effect-category-select-table
            :categories="targetNodes"
            :is-offer="isOffer"
            v-model="value.effectCategoryPriorities['target']"
            :error="getError('target')"
        />

        <effect-category-select-table
            :categories="workingEnvironmentNodes"
            :is-offer="isOffer"
            v-model="value.effectCategoryPriorities['workingEnvironment']"
            :error="getError('workingEnvironment')"
        />
    </div>
</template>

<script>
    import Button from 'primevue/button';
    import TreeTable from 'primevue/treetable';
    import Column from 'primevue/column';
    import Checkbox from 'primevue/checkbox';
    import Errors from "@/components/form/Errors";
    import SelectButton from 'primevue/selectbutton';
    import Dropdown from 'primevue/dropdown';
    import Steps from 'primevue/steps';
    import InputText from "primevue/inputtext/InputText";
    import InputNumber from "primevue/inputnumber/InputNumber";
    import {required, requiredIf} from "vuelidate/lib/validators";
    import Value from "@/mixin/Value";
    import EffectCategorySelectTable from "@/components/data/matchingEntity/EffectCategorySelectTable";

    const validCategories = (minimumValue) => (value) => {
        if (minimumValue) {
            if (!value) {
                return false;
            }

            return value.length > 0 && value.length <= 5;
        }

        if (!value) {
            return true;
        }

        return value.length < 5;
    };

    const priorityIsHundred = (isOffer) => (value) => {
        if (!isOffer) {
            return true;
        }

        let sum = 0;
        Object.keys(value).forEach((item) => {
            sum += value[item];
        });

        return sum === 100;
    };

    export default {
        mixins: [Value],
        components: {
            EffectCategorySelectTable,
            Checkbox,
            Button,
            Errors,
            TreeTable,
            Column,
            SelectButton,
            Dropdown,
            Steps,
            InputText,
            InputNumber,
        },
        validations() {
            return {
                value: {
                    impactBhiCategory: {
                        required: required,
                    },
                    typeCategory: {
                        required: required,
                    },
                }
            }
        },
        props: {
            isOffer: {
                type: Boolean,
                default: () => true,
            }
        },
        mounted() {
            this.$http.get('categories', {
                params: {
                    itemsPerPage: 300,
                    exists: {parent: false},
                    order: {position: 'ASC'}
                }
            }).then(response => {
                if (this.value.hasOwnProperty('@id')) {
                    if (this.value.impactBhiCategory.hasOwnProperty('@id')) {
                        this.value.impactBhiCategory = this.value.impactBhiCategory['@id'];
                    }

                    if (this.value.typeCategory.hasOwnProperty('@id')) {
                        this.value.typeCategory = this.value.typeCategory['@id'];
                    }
                }

                this.categories = response.data['hydra:member'];
            });
        },
        watch: {
            'value.effectCategoryPriorities': function(newVal) {
                if(typeof newVal === 'undefined') {
                    this.value.effectCategoryPriorities = {
                        target: [],
                        workingEnvironment: [],
                    }
                }
            }
        },
        methods: {
            getError(path) {
                if(!this.errors.hasOwnProperty('effectCategoryPriorities')) {
                    return null;
                }
                return this.errors.effectCategoryPriorities.filter((item) => item.indexOf(path) > -1)[0] ?? null;
            },
            mapToNode(object, hasParent = false) {
                let node = {
                    key: object['@id'],
                    data: {
                        name: object.name,
                        code: object.code,
                        hasParent: hasParent
                    }
                };
                if (object.hasOwnProperty('children') && object.children.length > 0) {
                    node['children'] = [];
                    Object.keys(object.children).forEach((item) => {
                        node.children.push(this.mapToNode(object.children[item], true));
                    })
                }

                return node;
            }
        },
        computed: {
            impactBhiOptions() {
                return this.categories.filter((item) => {
                    return item.type === 'impactBhi';
                })
            },
            typeOptions() {
                return this.categories.filter((item) => {
                    return item.type === 'type';
                })
            },
            workingEnvironmentNodes() {
                let nodes = [];
                this.categories.filter((item) => {
                    return item.type === 'workingEnvironment';
                }).forEach((item) => {
                    nodes.push(this.mapToNode(item));
                })

                return nodes;
            },
            targetNodes() {
                let nodes = [];
                this.categories.filter((item) => {
                    return item.type === 'target';
                }).forEach((item) => {
                    nodes.push(this.mapToNode(item));
                })

                return nodes;
            }
        },
        data() {
            return {
                categories: [],
            }
        },
    }
</script>
