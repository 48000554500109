<template>
    <div>
        <div class="field grid">
            <label class="col-fixed text-700 mt-1 w-4">
                Zeitraum
            </label>
            <div class="col">
                <Calendar
                    :min-date="minDate"
                    v-model="range"
                    :inline="true"
                    selectionMode="range"
                />
                <errors :errors="errors.start"/>
                <errors :errors="errors.end"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Value from "@/mixin/Value";
    import Calendar from 'primevue/calendar';
    import SelectButton from "primevue/selectbutton/SelectButton";
    import Errors from "@/components/form/Errors";

    export default {
        components: {Errors, SelectButton, Calendar},
        mixins: [Value],
        data() {
            return {
                range: null,
            }
        },
        computed: {
            minDate: function() {
                let minDate = new Date();
                minDate.setFullYear(minDate.getFullYear() - 1);
                return minDate;
            }
        },
        watch: {
            range: {
                handler: function(newVal) {
                    this.value['start'] = newVal[0];
                    this.value['end'] = newVal[1];
                    this.$emit('input', this.value);
                },
                deep: true
            }
        }
    }
</script>
