<template>
    <div>
        <div class="flex justify-content-between mb-4 align-items-center">
            <div class="text-2xl uppercase">
                {{ headline }}
            </div>
            <div class="block">
                <slot name="actions"></slot>
            </div>
        </div>

        <DataTable
            :value="records"
            :lazy="true"
            :paginator="true"
            :rows="itemsPerPage"
            ref="dt"
            :totalRecords="totalRecords"
            @page="onPage($event)"
            @sort="onSort($event)"
            @filter="onFilter($event)"
            :resizableColumns="true"
            class="p-datatable-sm"
            :filters.sync="filter"
        >
            <slot></slot>
            <template #empty>
                <div class="py-4 text-center">
                    Keine Einträge gefunden.
                </div>
            </template>
        </DataTable>
    </div>
</template>


<script>
    import DataTable from 'primevue/datatable';

    export default {
        props: {
            endpoint: {
                type: String,
                required: true,
            },
            headline: {
                type: String,
                required: true,
            },
            itemsPerPage: {
                type: Number,
                required: false,
                default: () => 10,
            },
            fixedQueryParams: {
                type: Object,
                required: false,
                default: () => {
                }
            },
            preSetParams: {
                type: Object,
                required: false,
                default: () => {
                }
            }
        },
        components: {
            DataTable
        },
        data() {
            return {
                filter: {
                    email: ''
                },
                loadingIndex: 0,
                records: {},
                totalRecords: 0,
                params: {},
            }
        },
        methods: {
            reload() {
                this.loadData();
            },
            loadData() {
                let params = {
                    page: (this.params.page ?? 0) + 1,
                    itemsPerPage: this.itemsPerPage,
                };

                if (this.params.sortField) {
                    params.order = {};
                    params.order[this.params.sortField] = this.params.sortOrder > 0 ? 'ASC' : 'DESC';
                }

                this.loadingIndex++;
                this.$http.get(
                    this.endpoint,
                    {
                        params: Object.assign(params, this.fixedQueryParams)
                    }
                ).then(response => {
                    this.loadingIndex--;
                    this.totalRecords = response.data['hydra:totalItems'];
                    this.records = response.data['hydra:member'];
                });
            },
            onPage(event) {
                this.params = event;
                this.loadData();
            },
            onSort(event) {
                this.params = event;
                this.loadData();
            },
            onFilter() {
                this.params.filters = this.filters;
                this.loadData();
            }
        },
        mounted() {
            this.params = Object.assign({
                first: 0,
                rows: this.$refs.dt.rows,
                sortField: null,
                sortOrder: null,
                filters: this.filters
            }, this.preSetParams);
            this.loadData();
        }
    }
</script>
