<template>
    <div>
        <div class="grid align-items-center mb-3">
            <div class="col">
                <div class="text-lg uppercase">
                    Wählen Sie zwischen einer und fünf beliebige Wirkungen od. Ziele der Dienstleistung
                </div>
            </div>
            <div class="col">
                <div class="p-text-right">
                    <div class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <InputText v-model="filter['global']" placeholder="Attribute suchen" size="50"/>
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="error">
                <p class="text-error my-0">{{error}}</p>
            </div>
        </div>

        <TreeTable
            :value="categories"
            class="mb-6"
            :indentation="2"
            :filters="filter"
            filter-mode="lenient"
        >
            <Column field="name" header="Attribute, Ziel & Wirkung" :expander="true" style="width: 800px;">
            </Column>
            <Column bodyStyle="text-align: right;">
                <template #body="slotProps">
                    <Checkbox
                        v-if="slotProps.node.data.hasParent"
                        :value="slotProps.node.key"
                        v-model="activeNodes"
                    />
                </template>
            </Column>
            <Column
                bodyStyle="text-align: right;"
                header="Wirkungswert"
                :headerStyle="{'text-align': 'right'}"
            >
                <template #body="slotProps">
                    <div
                        v-if="
                            slotProps.node.data.hasParent
                            && activeNodes.includes(slotProps.node.key)
                        "
                    >
                        <InputNumber
                            v-if="isOffer"
                            show-buttons
                            :min="0"
                            :max="100"
                            v-model="activeNodesPriority[slotProps.node.key]"
                        />

                        <Dropdown
                            v-if="!isOffer"
                            v-model="activeNodesPriority[slotProps.node.key]"
                            :options="[{id: 'high', label: 'Hohe Priorität'}, {id: 'medium', label: 'Mittlere Priorität'}, {id: 'low', label: 'Niedrige Priorität'}]"
                            option-label="label"
                            option-value="id"
                        />
                    </div>
                </template>
            </Column>
        </TreeTable>
    </div>

</template>

<script>
    import Checkbox from "primevue/checkbox";
    import TreeTable from "primevue/treetable";
    import Column from "primevue/column";
    import Dropdown from "primevue/dropdown";
    import InputNumber from "primevue/inputnumber";
    import InputText from "primevue/inputtext";

    export default {
        components: {
            Checkbox,
            TreeTable,
            Column,
            Dropdown,
            InputNumber,
            InputText
        },
        props: {
            value: {
                type: Object,
            },
            categories: {
                type: [Array, Object]
            },
            isOffer: {
                type: Boolean,
                default: false,
            },
            error: {
                type: [Boolean, String],
                default: false,
            }
        },
        mounted() {
            this.activeNodes = Object.keys(this.value);
            this.activeNodesPriority = this.value;
        },
        watch: {
            'activeNodes': function (newVal) {
                newVal.forEach((item) => {
                    if (
                        Object.keys(this.activeNodesPriority).includes(item)
                        && this.activeNodesPriority[item] === 'undefined'
                    ) {
                        this.$set(this.activeNodesPriority, item, 10);
                    }
                });

                this.$emit('input', this.activeNodesPriority);
            },
            'activeNodesPriority': {
                handler: {
                    function(newVal) {
                        console.log(newVal);
                        console.log(this.activeNodes);
                    }
                },
                deep: true,
            }
        },
        data() {
            return {
                activeNodes: [],
                activeNodesPriority: {},
                filter: {},
            }
        }
    }
</script>
