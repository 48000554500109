<template>
    <div v-if="editedEntity.id">
        <update-view-header
            :headline="entity.name"
            button-text="Zurück zur Übersicht"
            button-path="/providers"
        />
        <TabView>
            <TabPanel header="Allgemeine Daten">
                <div class="surface-card p-6 pb-5 border-1 surface-border">
                    <general-data-update-form
                        v-model="editedEntity"
                        :errors="editedEntityErrors"
                    />

                    <div class="surface-border border-top-1 opacity-50 mb-4 mt-4 py-0 col-12"></div>

                    <div class="text-center">
                        <Button type="submit" label="Aktualisieren" @click="submitEntity"></Button>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Kategorie, Ziel & Wirkung">
                <div class="surface-card p-6 pb-5 border-1 surface-border">
                    <categories-update-form
                        v-model="editedEntity"
                        :errors="editedEntityErrors"
                    />

                    <div class="text-center">
                        <Button type="submit" label="Aktualisieren" @click="submitEntity"></Button>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Organisation">
                <div class="surface-card p-6 border-1 surface-border">
                    <organization-update-form
                        v-if="editedEntity.id"
                        v-model="editedEntity"
                        :errors="editedEntityErrors"
                        :is-offer="true"
                    />

                    <div class="text-center">
                        <Button
                            type="submit"
                            label="Aktualisieren"
                            @click="submitEntity"/>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Passende Anfragen">
                <no-module/>
            </TabPanel>
            <TabPanel header="Reporting & Abrechnung">
                <no-module/>
            </TabPanel>
        </TabView>
    </div>
</template>

<script>
    import Button from "primevue/button/Button";
    import UpdateView from "@/mixin/UpdateView";
    import UpdateViewHeader from "@/components/view/UpdateViewHeader";
    import TabView from "primevue/tabview/TabView";
    import TabPanel from "primevue/tabpanel/TabPanel";
    import GeneralDataUpdateForm from "@/components/data/offer/GeneralDataUpdateForm";
    import CategoriesUpdateForm from "@/components/data/matchingEntity/CategoriesUpdateForm";
    import OrganizationUpdateForm from "../../../../shared/components/OrganizationUpdateForm";
    import NoModule from "@/components/NoModule";
    import DataTableAndEdit from "@/components/data/offer/availability/DataTableAndEdit";

    export default {
        components: {
            DataTableAndEdit,
            NoModule,
            OrganizationUpdateForm,
            CategoriesUpdateForm,
            Button,
            GeneralDataUpdateForm,
            UpdateViewHeader,
            TabView,
            TabPanel
        },
        mixins: [UpdateView],
        methods: {
            getApiEndpoint: () => 'offers'
        }
    }
</script>
