<template>
    <div v-if="offer.id">
        <div v-if="view === 'create' || view === 'edit'">
            <div class="field grid">
                <label class="col-fixed text-700 mt-1 w-4">
                    Typ
                </label>
                <div class="col">
                    <Dropdown
                        v-model="editedEntity['@context']"
                        option-value="id"
                        option-label="label"
                        class="w-full"
                        :options="[{id: '/contexts/AvailabilityRange', label: 'Zeitraum'}, {id: '/contexts/AvailabilityPermanent', label: 'Permanent'}]"
                    />
                </div>
            </div>

            <base-update-form v-model="editedEntity" :errors="editedEntityErrors"/>
            <range-update-form
                v-model="editedEntity"
                :errors="editedEntityErrors"
                v-if="editedEntity['@context'] === '/contexts/AvailabilityRange'"
            />

            <div class="surface-border border-top-1 my-5"></div>

            <div class="text-center">
                <a @click="view = 'overview';">
                    <Button class="p-button-secondary mr-4 p-button p-component" label="Abbrechen"/>
                </a>
                <Button class="p-button" @click="submitEntity">
                    <template v-if="editedEntity.id">Aktualisieren</template>
                    <template v-else>Erstellen</template>
                </Button>
            </div>
        </div>

        <data-table-view
            v-if="view === 'overview'"
            headline="Verfügbarkeiten"
            endpoint="availabilities"
            ref="dataTable"
            :fixed-query-params="{offer: offer['@id']}"
        >
            <template slot="actions">
                <a @click="createEntity">
                    <Button
                        label="Hinzufügen"
                        class="p-button-sm cursor-pointer"
                    />
                </a>
            </template>
            <Column header="Typ" sortable>
                <template #body="slotProps">
                    <template v-if="slotProps.data['@type'] === 'AvailabilityPermanent'">
                        Permanent verfügbar
                    </template>
                    <template v-if="slotProps.data['@type'] === 'AvailabilityRange'">
                        Zeitweise verfügbar
                    </template>
                </template>
            </Column>
            <Column header="Zeitraum" field="start" sortable>
                <template #body="slotProps">
                    <template v-if="slotProps.data['@type'] === 'AvailabilityRange'">
                        Von {{ slotProps.data.start|formatDate('LL') }}<br>bis {{ slotProps.data.end|formatDate('LL') }}
                    </template>
                </template>
            </Column>
            <Column
                header="Maximale Teilnehmer"
                field="openCapacity"
            />
            <Column header="Modelle bzw. Standorte">
                <template #body="slotProps">
                    <ul v-for="placeType in slotProps.data.placeTypes" class="pl-0 ml-0">
                        <li>
                            {{placeType|dataTrans('model.availability.placeType')}}
                            <template v-if="placeType === 'extern'"><br>{{slotProps.data.zip}}</template>
                            <template v-if="placeType === 'intern'"><br>Max. {{slotProps.data.maximalDistance}}km</template>
                        </li>
                    </ul>
                </template>
            </Column>
            <Column header="Barrierefrei">
                <template #body="slotProps">
                    <i class="pi"
                       :class="{
                            'true-icon pi-check-circle': slotProps.data.barrierFree,
                        }"
                    ></i>
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <a @click="removeAvailability(slotProps.data)"
                       class="mr-2 cursor-pointer"
                    >
                        <i class="pi pi-trash"></i>
                    </a>
                    <a
                        @click="editEntity(slotProps.data)"
                        v-if="slotProps.data.userCanUpdate"
                        class="cursor-pointer"
                    >
                        <i class="pi pi-pencil"></i>
                    </a>
                </template>
            </Column>
        </data-table-view>
    </div>
</template>

<script>
    import Dropdown from "primevue/dropdown/Dropdown";
    import DataTableView from "@/components/DataTableView";
    import RangeUpdateForm from "@/components/data/offer/availability/RangeUpdateForm";
    import BaseUpdateForm from "@/components/data/offer/availability/BaseUpdateForm";
    import UpdateView from "@/mixin/UpdateView";
    import Button from "primevue/button";
    import Column from "primevue/column/Column";

    export default {
        mixins: [UpdateView],
        components: {Button, RangeUpdateForm, BaseUpdateForm, DataTableView, Dropdown, Column},
        props: {
            offer: {
                type: Object,
                required: true,
            }
        },
        methods: {
            removeAvailability(availability) {
                console.log('test');
                this.$confirm.require({
                    message: 'Soll die Verfügbarkeit wirklich entfernt werden?',
                    header: 'Bestätigung',
                    rejectLabel: 'Abbrechen',
                    acceptLabel: 'Ja',
                    accept: () => {
                        this.$deleteFromApi('availabilities', availability['id'], () => {
                            this.$refs.dataTable.reload();
                        });
                    }
                });
            },
            createEntity() {
                this.editedEntityErrors = {};
                this.editedEntity = this.getCreateObject();
                this.view = 'create';
            },
            isRouteEntityGuesserDisabled: () => true,
            getCreateObject() {
                return {
                    type: 'permanent',
                    offer: this.offer['@id'],
                    barrierFree: false,
                }
            },
            editEntity(entity) {
                this.loadEntity(entity);
                this.view = 'edit';
            },
            getUpdateAction(entity) {
                this.view = 'overview';
                this.$refs.dataTable.reload();
                this.$forceUpdate();
            },
            getApiEndpoint() {
                if (this.editedEntity.hasOwnProperty('@context') && this.editedEntity['@context'] === '/contexts/AvailabilityRange') {
                    return 'availability-ranges';
                } else {
                    return 'availability-permanents';
                }
            }
        },
        data() {
            return {
                view: 'overview'
            }
        }
    }
</script>
