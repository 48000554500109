<template>
    <div>
        <div class="field grid mb-4"
             v-for="field in fields"
        >
            <template v-if="field.key !== 'range' || searchPeriod === 'period'">
                <label class="col-fixed text-700 mt-1 w-4">
                    {{ field.label }}
                </label>
                <div class="col">
                    <div class="p-inputgroup w-full" v-if="field.key === 'maximalDistance'">
                        <input-number v-model="value[field['key']]" class="w-10"/>
                        <span class="p-inputgroup-addon w-2 text-center">km</span>
                    </div>
                    <Calendar
                        v-if="field.key === 'range'"
                        :min-date="minDate"
                        v-model="range"
                        :inline="false"
                        selectionMode="range"
                        class="w-full"
                    />
                    <Multiselect
                        v-if="field.key === 'placeTypes'"
                        class="w-full"
                        :options="field.options"
                        v-model="value[field['key']]"
                        option-value="id"
                        option-label="label"
                    />
                    <SelectButton
                        class="w-full"
                        v-if="field.key === 'searchPeriod'"
                        v-model="searchPeriod"
                        :options="[{label: 'Ab sofort', id: 'now'}, {label: 'Zeitraum', id: 'period'}]"
                        option-label="label"
                        option-value="id"
                    />
                    <InputNumber
                        class="w-full"
                        v-if="field.key === 'costs' || field.key === 'budget'"
                        v-model="value[field['key']]"
                        mode="currency"
                        currency="EUR"
                        locale="de-DE"
                    />
                    <InputNumber
                        class="w-full"
                        v-if="field.key === 'attendances'"
                        v-model="value[field['key']]"
                        :min="0"
                    />
                    <Dropdown
                        v-if="field.type === 'Dropdown'"
                        class="w-full"
                        :options="getFields(field['optionKey'])"
                        option-label="label"
                        option-value="id"
                        v-model="value[field['key']]"
                    />

                    <Multiselect
                        v-if="field.type === 'Multiselect'"
                        class="w-full"
                        :options="getFields(field['optionKey'])"
                        v-model="value[field['key']]"
                        option-label="label"
                        option-value="id"
                    />
                    <errors
                        v-if="field.key !== 'units'"
                    />

                    <errors :errors="errors[field['key']]"/>

                    <div v-if="field.key === 'units'">
                        <Dropdown
                            :options="priceTypeOptions"
                            v-model="priceType"
                            option-value="id"
                            option-label="label"
                            class="w-full"
                        />

                        <template v-if="priceType === 'duration'">
                            <div class="grid w-full mt-2">
                                <div class="col col-3">
                                    <input-number
                                        v-model="unitCount"/>
                                </div>
                                <div class="col col-9">
                                    <Dropdown
                                        :options="unitTypeOptions"
                                        v-model="unitType"
                                        option-value="id"
                                        option-label="label"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import Value from "../mixin/Value";
    import Errors from "./Errors";
    import Dropdown from "primevue/dropdown/Dropdown";
    import InputNumber from "primevue/inputnumber";
    import Multiselect from "primevue/multiselect";
    import Calendar from "primevue/calendar/Calendar";
    import SelectButton from "primevue/selectbutton/SelectButton";

    export default {
        mixins: [Value],
        props: {
            isOffer: {
                type: Boolean,
                default: () => true
            }
        },
        components: {
            Errors,
            Dropdown,
            Multiselect,
            InputNumber,
            SelectButton,
            Calendar
        },
        computed: {
            minDate: function () {
                let minDate = new Date();
                minDate.setFullYear(minDate.getFullYear() - 1);
                return minDate;
            },
            fields() {
                let baseFields = [
                    {
                        key: 'format',
                        label: 'Format',
                        type: 'Dropdown',
                        optionKey: 'matchingEntity.format',
                    },
                    {
                        key: 'groupSize',
                        label: 'Gruppengröße',
                        type: 'Dropdown',
                        optionKey: 'groupSize',
                    },
                    {
                        key: 'participantAgeGroups',
                        label: 'Altersgruppe',
                        type: 'Multiselect',
                        optionKey: 'participantAgeGroup',
                    },
                    {
                        key: 'participantGenders',
                        label: 'Geschlecht',
                        type: 'Multiselect',
                        optionKey: 'participantGender',
                    },
                    {
                        key: 'participantLevels',
                        label: 'Ebene / Hierarchie',
                        type: 'Multiselect',
                        optionKey: 'participantLevel',
                    },
                ];

                if (this.isOffer) {
                    return baseFields.concat([
                        {
                            key: 'costs',
                            label: 'Kosten',
                            type: 'Number',
                        },
                        {
                            key: 'units',
                            label: 'Einheiten',
                            optionKey: 'units',
                        }
                    ]);
                } else {
                    return [
                        {
                            key: 'attendances',
                            label: 'Anzahl Personen',
                        },
                        {
                            key: 'budget',
                            label: 'Budget',
                            type: 'Currency',
                        },
                        {
                            key: 'searchPeriod',
                            label: 'Zeitraum',
                        },
                        {
                            key: 'range',
                            label: 'Zeitspanne'
                        },
                        {
                            key: 'placeTypes',
                            label: 'Standort',
                            options: Object
                                .entries(this.$store.getters.settings.model.request.placeType)
                                .map(([key, value]) => ({id: key, label: value}))
                        },
                        {
                            key: 'maximalDistance',
                            label: 'Maximale Entfernung'
                        }
                    ].concat(baseFields);
                }
            }
        },
        mounted() {
            if (this.value.hasOwnProperty('id')) {
                this.range[0] = this.value.start;
                this.range[1] = this.value.end;
                this.$forceUpdate();
            }

            if (!this.value.hasOwnProperty('unitType')) {
                this.value.unitType = 'day';
                this.value.units = 8;
                this.$emit('input', this.value);
            }
        },
        methods: {
            getFields(name) {
                let options = {};

                if (name.indexOf('matchingEntity.format') >= 0) {
                    options = Object.assign({}, this.$store.getters.settings.model.matchingEntity['format']);
                } else {
                    options = Object.assign({}, this.$store.getters.settings.model.offer[name]);
                }

                return Object
                    .entries(options)
                    .map(([key, value]) => ({id: key, label: value}));
            },
        },
        watch: {
            priceType: function (newVal) {
                if (newVal === 'unit') {
                    this.value['units'] = null;
                } else if (newVal === 'duration') {
                    this.$set(this.value, 'unitCount', 2);
                    this.$set(this.value, 'unitType', 'day');
                    this.$set(this.value, 'units', 16);
                    this.$emit('input', this.value);
                }
            },
            range: {
                handler: function (newVal) {
                    this.value['start'] = newVal[0];
                    this.value['end'] = newVal[1];
                    this.$emit('input', this.value);
                },
                deep: true
            },
            searchPeriod: function (newVal) {
                if (newVal === 'now') {
                    this.value['start'] = null;
                    this.value['end'] = null;
                    this.$emit('input', this.value);
                }
            },
            unitCount: function (newVal) {
                if (this.unitType === 'half') {
                    this.value['units'] = newVal * 4;
                } else if (this.unitType === 'hour') {
                    this.value['units'] = newVal * 1;
                } else {
                    this.value['units'] = newVal * 8;
                }
                this.$emit('input', this.value);
            },
            unitType: function (newVal) {
                if (newVal === 'half') {
                    this.value['units'] = this.unitCount * 4;
                } else if (newVal === 'hour') {
                    this.value['units'] = this.unitCount;
                } else {
                    this.value['units'] = this.unitCount * 8;
                }
                this.$emit('input', this.value);
            }
        },
        data() {
            return {
                searchPeriod: 'period',
                range: [],
                unitCount: 1,
                priceType: 'duration',
                priceTypeOptions: [
                    {
                        id: 'unit',
                        label: 'Pauschal'
                    },
                    {
                        id: 'duration',
                        label: 'Zeiteinheit'
                    },
                ],
                unitType: 'day',
                unitTypeOptions: [
                    {
                        id: 'hour',
                        label: 'Stunde/n',
                    },
                    {
                        id: 'half',
                        label: 'halber Tag',
                    },
                    {
                        id: 'day',
                        label: 'Ganzer Tag',
                    }
                ],
            }
        }
    }
</script>
