<template>
    <div>
        <div class="field grid mb-4">
            <label class="col-fixed text-700 mt-1 w-4">
                Bezeichnung
            </label>
            <div class="col">
                <InputText class="w-full" v-model="value['name']"/>
                <errors :errors="errors['name']"/>
            </div>
        </div>

        <div class="field grid mb-4 align-items-start">
            <label class="col-fixed text-700 mt-1 w-4">
                Kurzbeschreibung
            </label>
            <div class="col">
                <Textarea v-model="value['description']" :autoResize="true" rows="10" class="w-full"/>
                <errors :errors="errors['description']"/>
            </div>
        </div>

        <div class="field grid mb-4 align-items-start">
            <label class="col-fixed text-700 mt-1 w-4">
                Sichtbarkeit
            </label>
            <div class="col">
                <Dropdown
                    v-model="value['visibilityStatus']"
                    :options="getVisibilityOptions"
                    optionLabel="label"
                    optionValue="id"
                />
                <errors :errors="errors['visibilityStatus']"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Value from "@/mixin/Value";
    import Errors from "@/components/form/Errors";
    import InputText from "primevue/inputtext/InputText";
    import Textarea from "primevue/textarea";
    import Dropdown from "primevue/dropdown";

    export default {
        components: {Errors, InputText, Textarea, Dropdown},
        mixins: [Value],
        computed: {
            getVisibilityOptions() {
                return Object
                    .entries(this.$store.getters.settings.model.offer['visibilityStatus'])
                    .map(([key, value]) => ({id: key, label: value}));
            }
        }
    }
</script>
